import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.less'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
	<React.StrictMode>
		<ConfigProvider direction="rtl"
			theme={{
				token: {
					colorText: '#071A50',
					colorPrimary: '#9966FF',
					fontFamily: 'Noto Sans Hebrew'
				}
			}}>
			<App />
		</ConfigProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
