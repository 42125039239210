import React, { FC, useRef, useState } from 'react'
import { Layout, Image, Typography, Input, Button, Space, Checkbox, InputRef, Tooltip, Spin } from 'antd'
import {CheckCircleOutlined} from '@ant-design/icons'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {createPayment, getProductData} from '../../api'
import { useSearchParams } from 'react-router-dom'
import { ProductData } from './types'
const {Footer, Content} = Layout
const {Title} = Typography
import './Payment.less'


export type ProductType = 'bundles'| 'courses'


const Payment: FC = () => {
	const [searchParams,] = useSearchParams()
	const [productID,] = useState<string>(searchParams.get('product_id') || '')
	const [productType,] = useState<ProductType>(searchParams.get('product_type') as ProductType)
	const [userID,] = useState<string>(searchParams.get('user_id') || '')

	const [coupon, setCoupon] = useState<string>('')
	const [isApproved, setIsApproved] = useState<boolean>(false)
	const [payment, setPayment] = useState<boolean>(false)

	const inputRef = useRef<InputRef>(null)

	const {data: product, isRefetching: isProductRefetching} = useQuery<ProductData>({
		queryKey: ['product', productID, productType, coupon],
		queryFn: () => getProductData(productType, productID, coupon),
		placeholderData: keepPreviousData,

	})
	const {data: paymentURL, isFetching: isPaymentFetching} = useQuery<string>({
		queryKey: ['payment', productID, productType, coupon, userID],
		queryFn: () => createPayment(productType, productID, coupon, userID),
		enabled: payment

	})

	const isCouponApplied = !!product?.coupon_discount

	if(paymentURL) {
		window.location.href = paymentURL
		return null
	}

	const getProductTypeText = () => {
		switch(productType) {
		case 'bundles':
			return 'חבילה'
		case 'courses':
			return 'קורס'
		default:
			return ''
		}
	}
	return <Layout className='main-layout'>
		{product ? 
			<Content className='main-content'>
				<Image className='logo' src="https://lwfiles.mycourse.app/63c0b74074210caf235b1223-public/0d60fdefe7fd5146d42e16e8cea4f814.png" preview={false}/>
				<div className='product-details'>
					<Image className='product-image' src={product.courseImage} preview={false}/>
					<div className='product-data'>
						<span>{`פרטי הזמנה: ${getProductTypeText()}`}</span>
						<span className='product-title'>{product.title}</span>
						<span className='product-description'>{product.description}</span>
					</div>
				</div>
				<div className='payment-container'>
					<Title className='payment-title'>תשלום</Title>
					<div className='payment-details'>
						<div>
							<Title className='coupon-title'>יש לך קופון?</Title>
							<Space.Compact className='coupon-input-container'>
								<Input  className='coupon-input' prefix={isCouponApplied ? <CheckCircleOutlined /> : null} placeholder='הכנס קופון' ref={inputRef} value={isCouponApplied ? `${product.coupon_discount.toFixed(2)}₪-`: undefined} disabled={!!coupon && isCouponApplied}/>
								<Button className='ok-button' loading={isProductRefetching} type="primary" onClick={() => setCoupon(!isCouponApplied ? inputRef.current?.input?.value || '' : '')
								}>{coupon && isCouponApplied && !isProductRefetching ? 'הסר' : 'אישור'}</Button>
							</Space.Compact>
						</div>
						<div className='bad-coupon'>
							{coupon && !isCouponApplied && !isProductRefetching && 'אופס, נראה שהקופון שאתה מנסה לממש לא חל על זה'}
						</div>
						<Checkbox className='terms' checked={isApproved} onChange={(e) => setIsApproved(e.target.checked)}> * אני מסכים עם <a href='https://www.univeli.com/terms' target="_blank" rel="noopener noreferrer" className='terms-link'>תנאים והגבלות</a></Checkbox>
						<div className='checkout-container'>
							<Title className='total'>{`סך הכל: ₪${product.final_price.toFixed(2)}`}</Title>
							<Tooltip title={!isApproved ? 'אנא אשר את התנאים כדי לעבור לתשלום' : null}>
								<Button type='primary' className='payment-button' disabled={!isApproved} loading={isPaymentFetching} onClick={() => setPayment(!payment)}>{`קנה ₪${product.final_price.toFixed(2)}`}</Button>
							</Tooltip>
						</div>
					</div>
				</div>
			</Content> 
			: <div className='spin-container'>
				<Spin size='large'/>
			</div>}
		<Footer className='footer'>Copyright ©Univeli 2024</Footer>
	</Layout>
}

export default Payment