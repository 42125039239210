import axios from 'axios'
import { ProductType, ProductData } from './Pages/Payment/types'
const API_URL = 'https://api.univeli.com'


export const getProductData = async (productType: ProductType, productID: string, coupon: string) : Promise<ProductData> => {
	const url = new URL(`${API_URL}/${productType}/${productID}`)
	if(coupon) {
		url.searchParams.append('coupon', coupon)
	}

	const res = await axios.get(url.toString())
	return res.data
    
}

export const createPayment = async (productType: ProductType, productID: string, coupon: string, user_id: string) : Promise<string> => {
	const url = new URL(`${API_URL}/${productType}/${productID}/payment`)
	const res = await axios.post(url.toString(), {
		coupon: coupon ? coupon : undefined,
		user_id
	})
	return res.data
    
}
