import React, { FC, useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import WebFont from 'webfontloader'
import Payment from './Pages/Payment/Payment'
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
})


const App: FC = () => {
	useEffect(() => {
		WebFont.load({ google: {families: ['Noto Sans Hebrew'] }
		})
	}, [])
	return  <QueryClientProvider client={queryClient}>
		<BrowserRouter>
			<Routes>
				<Route path="/payment" element={<Payment />}/>
				<Route path="/payment/success" element={<Success/>}/>
				<Route path="/payment/error" element={<Error/>}/>
			</Routes>
		</BrowserRouter>
	</QueryClientProvider>

}

const Success: FC = () =>  {
	window.location.href = 'https://www.univeli.com/start'
	return null
}

const Error: FC = () =>  {
	window.location.href = 'https://www.univeli.com/payment-error'
	return null
}
	
export default App